/** SimpleIDB **/
const SimpleIDB = {
  initialize(store, db_name) {
        return new Promise((resolve, reject) => {
      var _upRequest = indexedDB.open(db_name);

      _upRequest.onsuccess = function (e) {
        var thisDb = e.target.result;
        var version = parseInt(thisDb.version) + 1;
        thisDb.close();
        var openRequest = indexedDB.open(db_name, version);

        //handle setup, this will be run by Firefox
        openRequest.onupgradeneeded = function (e) {
          console.log("running onupgradeneeded");
          var thisDb = e.target.result;

          //Create Employee
          if (!thisDb.objectStoreNames.contains(store)) {
            console.log("make the  objectstore");
            thisDb.createObjectStore(store);
            // var objectStore = thisDb.createObjectStore(store, {
            //   keyPath: "id",
            //   autoIncrement: true,
            // });
            // objectStore.createIndex("searchkey", "searchkey", {
            //   unique: false,
            // });
          }
          thisDb.close();
          resolve();
        };

        openRequest.onsuccess = function (e) {
          var db = e.target.result;

          db.onerror = function (e) {
            alert("Sorry, an unforseen error was thrown.");
            console.log("***ERROR***");
            console.dir(e.target);
          };

          db.close();
          resolve();
        };
      };
      _upRequest.onerror = function () {
        reject(_upRequest.error);
      };
    });
  },

  get(key, store, db_name) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(db_name);
      oRequest.onsuccess = function () {
        let db = oRequest.result;
        let tx = db.transaction(store, "readonly");
        let st = tx.objectStore(store);
        let gRequest = st.get(key);
        gRequest.onsuccess = function () {
          resolve(gRequest.result);
        };
        gRequest.onerror = function () {
          reject(gRequest.error);
        };
      };
      oRequest.onerror = function () {
        reject(oRequest.error);
      };
    });
  },

  getAll(store, db_name) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(db_name);
      oRequest.onsuccess = function () {
        let db = oRequest.result;
        let tx = db.transaction(store, "readonly");
        let st = tx.objectStore(store);
        let gRequest = st.getAll();
        gRequest.onsuccess = function () {
          resolve(gRequest.result);
        };
        gRequest.onerror = function () {
          reject(gRequest.error);
        };
      };
      oRequest.onerror = function () {
        reject(oRequest.error);
      };
    });
  },

  set(key, value, store, db_name) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(db_name);
      oRequest.onsuccess = function () {
        let db = oRequest.result;
        let tx = db.transaction(store, "readwrite");
        let st = tx.objectStore(store);
        let sRequest = st.put(value, key);
        sRequest.onsuccess = function () {
          resolve();
        };
        sRequest.onerror = function () {
          reject(sRequest.error);
        };
      };
      oRequest.onerror = function () {
        reject(oRequest.error);
      };
    });
  },

  remove(key, store, db_name) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(db_name);
      oRequest.onsuccess = function () {
        let db = oRequest.result;
        let tx = db.transaction(store, "readwrite");
        let st = tx.objectStore(store);
        let rRequest = st.delete(key);
        rRequest.onsuccess = function () {
          resolve();
        };
        rRequest.onerror = function () {
          reject(rRequest.error);
        };
      };
      oRequest.onerror = function () {
        reject(oRequest.error);
      };
    });
  },
};
export default {
  SimpleIDB,
};
