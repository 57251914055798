import dotenv from "dotenv";
dotenv.config();
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/index.js";
import {load,isExtension} from 'fulcrum-extensions';


Vue.config.productionTip = false;

function
    createApp() {


    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
}
if (isExtension) {

    load(({ data }) => {
        //debugger;

        store.dispatch("setFromFulcrum", true);
        localStorage.setItem("startParameters", JSON.stringify(data));
        localStorage.setItem("startData", JSON.stringify(data));            

        createApp();

    });

} else {
    store.dispatch("setFromFulcrum", false);

    createApp();

}

