import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "licencevalidation",
    component: () => import("../components/Licence/LicenceValidation.vue"),
    meta: { guest: true },
  },
  {
    path: "*",
    name: "licencevalidation",
    component: () => import("../components/Licence/LicenceValidation.vue"),
    meta: { guest: true },
  },
  {
    path: "/licence-registration",
    name: "licenceregistration",
    component: () => import("../components/Licence/LicenceRegistration.vue"),
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../components/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/startnewpole",
    name: "startNewPole",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/startNewPole.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/damageDetail",
    name: "DamageDetail",
    component: () => import("../components/Damages/DamageDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/hollowPole",
    name: "HollowPoleDamageDetail",
    component: () => import("../components/Damages/HollowPoleDamageDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/damageRecord",
    name: "DamageRecord",
    component: () => import("../components/Damages/DamageRecord.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/evaluation",
    name: "Evaluation",
    component: () => import("../components/Evaluation/Evaluation.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/poleInfo",
    name: "PoleInformation",
    component: () => import("../components/PoleInformation.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../components/Settings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../components/Support.vue"),
    meta: { guest: true },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
