import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isAuthenticated: false,
    fromFulcrum:false,
    poleRecord: {},
    damageRecord: {},
  },

  mutations: {
    SET_IS_AUTHENTICATED: (state, payload) => {
      state.isAuthenticated = payload;
    },
    SET_FROMFULCRUM: (state, payload) => {
      state.fromFulcrum = payload;
    },

    SET_POLE_RECORD: (state, payload) => {
      state.poleRecord = payload;
    },

    SET_DAMAGE_RECORD: (state, payload) => {
      state.damageRecord = payload;
    },
  },

  actions: {
    setIsAuthenticated({ commit }, payload) {
      commit("SET_IS_AUTHENTICATED", payload);
    },
    
      setFromFulcrum({ commit }, payload) {
        commit("SET_FROMFULCRUM", payload);
      },

    setPoleRecord({ commit }, payload) {
      commit("SET_POLE_RECORD", payload);
    },

    setDamageRecord({ commit }, payload) {
      commit("SET_DAMAGE_RECORD", payload);
    },
  },

  getters: {
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    fromFulcrum: (state) => {
      return state.fromFulcrum;
    },

    getPoleRecord: (state) => {
      return state.poleRecord;
    },

    getDamageRecord: (state) => {
      return state.damageRecord;
    },
  },

  modules: {},
});
