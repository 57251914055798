<template>
    <div>
        <router-view />
    </div>
</template>
<!-- Fulcrum extension script snippet, do not modify -->


<script>
    /* eslint-disable */
    import SimpleIDB from "./api/indexedDBService.js";
    import { Device } from "@capacitor/device";
    import { store } from "./store/index.js";
    /* eslint-disable no-console */

    export default {
       created() {
            const MY_NAMESPACE = "SASCAPP";
            let deviceinfo = "";
            //debugger;
            localStorage.setItem("InitialLoad", "Yes");
            localStorage.setItem("ErudaEnabled", "No");

            Device.getInfo().then(
                (info) => {
                    deviceinfo = info;
                    if (deviceinfo.model && deviceinfo.model == "Macintosh")
                        localStorage.setItem("model", "iPad");
                    else localStorage.setItem("model", deviceinfo.model);
                },
                (e) => console.log("there was an error", e)
            );

            let self = this;
            this.initialize("pole-data", "SASC_DB")
                .then(() => {
                    //debugger;
                    self.initialize("damage-data", "SASC_DB");
                })
                .catch(() =>
                { });
            //store.dispatch("setPoleRecord", JSON.stringify(this.poleData));

            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                console.log("enumerateDevices() not supported.");
                return;
            }
         
        },
        methods: {
            initialize(db_store, db_name) {

                //var i = new URLSearchParams(location.search), n = u(i.get("extension")) ?? {}; function u(e) { try { return JSON.parse(e) } catch { return null } } window.Fulcrum = { id: n.id, url: n.url, data: n.data ?? {}, origin: n.origin, init: () => { Fulcrum.isReady = !0, Fulcrum.onReadyOnce?.() }, ready: e => { Fulcrum.onReadyOnce = () => { Fulcrum.isReady && !Fulcrum.onReadyCalled && (Fulcrum.onReadyCalled = !0, e(Fulcrum)) }, Fulcrum.onReadyOnce() }, send: (e, { close: r = !1 } = {}) => { e = e ?? {}; let s = { id: Fulcrum.id, url: Fulcrum.url, data: e, close: r }; window.webkit?.messageHandlers ? window.webkit.messageHandlers.extensionListener.postMessage(JSON.stringify(s)) : window.parent && window.parent.postMessage({ extensionMessage: s }, Fulcrum.origin) }, finish: e => { Fulcrum.send(e, { close: !0 }) } }; window.addEventListener("DOMContentLoaded", Fulcrum.init);  

                //this.initialized = true
                try {
                    return SimpleIDB.SimpleIDB.initialize(db_store, db_name);
                } catch (e) {
                    console.log(e.message);
                }
            },
            insertObject(key, value, db_store, db_name) {
                //this.error1 = null
                try {
                    let jsonVal = value.includes("{") ? JSON.parse(value) : value;
                    return SimpleIDB.SimpleIDB.set(key, jsonVal, db_store, db_name);
                } catch (e) {
                    console.log(e.message);
                }
            },


        },
        
    };
</script>
<style lang="scss">
    @font-face {
        font-family: "Roboto-Regular";
        font-style: normal;
        font-weight: normal;
        src: local("Roboto-Regular"), url("./assets/fonts/Roboto-Regular.ttf") format("ttf");
    }

    @font-face {
        font-family: "Roboto-Medium";
        font-style: normal;
        font-weight: normal;
        src: local("Roboto-Medium"), url("./assets/fonts/Roboto-Medium.ttf") format("ttf");
    }

    @font-face {
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: normal;
        src: local("Roboto-Bold"), url("./assets/fonts/Roboto-Bold.ttf") format("ttf");
    }

    @font-face {
        font-family: "Roboto-Black";
        font-style: normal;
        font-weight: normal;
        src: local("Roboto-Black"), url("./assets/fonts/Roboto-Black.ttf") format("ttf");
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        padding: 0px;
        margin: 0px;
        font-family: "Roboto-Regular", Verdana, sans-serif;
        box-sizing: border-box;
    }

    //Text alignment
    .textCenter {
        text-align: center;
    }

    .txtUppercase {
        text-transform: uppercase;
    }

    //margin
    .martpbt_1 {
        margin: 1rem 0px;
    }

    .mb-1 {
        margin-bottom: 10px;
    }

    .mb-2 {
        margin-bottom: 20px;
    }

    //form
    .formList {
        text-align: left;

        label {
            color: #000;
            font-size: 14px;
            // font-weight: 600;
            display: inline-block;
            margin-bottom: 5px;

            span {
                color: #f00;
            }
        }

        .formGroup {
            margin-bottom: 1rem;
        }

        .formControl {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #003468;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 4px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            box-sizing: border-box;
        }

        .formDropDown {
            width: 100%;
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #003468;
            border-radius: 4px;
        }
    }
</style>
